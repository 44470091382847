import { Page, PageProps } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

const TablePageWrapper = styled.div`
  > .Polaris-Page {
    max-width: max(64rem, 75%);
  }
`;

export const TablePage = ({ children, ...rest }: PageProps) => {
  return (
    <TablePageWrapper>
      <Page {...rest}>{children}</Page>
    </TablePageWrapper>
  );
};
