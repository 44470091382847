import { SmartrrFilterType } from "@smartrr/shared/utils/paginatedQuery";

export const getArrayFromFilter = (filter: SmartrrFilterType, filterField: string): Array<any> => {
  const filterValue = filter[filterField];
  if (Array.isArray(filterValue)) {
    return filterValue;
  }
  if (typeof filterValue === "string") {
    return [filterValue];
  }

  return [];
};

export const getStringFromFilter = (filter: SmartrrFilterType, filterField: string): string => {
  const filterValue = filter[filterField];
  if (Array.isArray(filterValue)) {
    return filterValue.join(";");
  }
  if (typeof filterValue === "string") {
    return filterValue;
  }

  return "";
};
