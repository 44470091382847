import { delay } from "@smartrr/shared/utils/delay";

interface Props<T, S> {
  items: T[];
  filterFunction: (item: T) => S;
  waitInterval: number;
  chunkSize: number;
}

export const mapArrayEntityWithDelay = async <T, S>({
  items,
  filterFunction,
  waitInterval = 500,
  chunkSize = 250,
}: Props<T, S>): Promise<S[]> => {
  const rows: S[] = [];
  for (let index = 0; index < items.length; index++) {
    //function delays every 'chunkSize' elements to free main thread for a couple of seconds
    //that way when user presses export it won`t freeze the app
    if (index % chunkSize === 0) {
      await delay(waitInterval);
    }
    const item = items[index];
    rows.push(filterFunction(item));
  }
  return rows;
};
