import { useApolloClient } from "@apollo/client";
import { Select, Text } from "@shopify/polaris";
import { SmartrrFilterType } from "@smartrr/shared/utils/paginatedQuery";
import { debounce, omit } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { getArrayFromFilter, getStringFromFilter } from "@vendor-app/utils/filterHelpers";

import { CombinedSearchOptions, getEmailOptions, getIdOptions, useFilterUpdate } from "./libs";
import { TableSearchProps } from "./libs/types";
import { useSmartrrVendorDispatch } from "../../_state/typedVendorReduxHooks";
import { AutoCompleteWithTextField } from "../../AdminRoute/components/elements/AutocompleteWithTextField";

const selectId = "search-type-select";

const StyledWrapper = styled.div<{ hasManyOptions: boolean }>`
  display: flex;
  width: 100%;

  > div {
    &:first-child {
      width: 100%;
      ${({ hasManyOptions }) =>
        hasManyOptions
          ? `
        .Polaris-TextField__Backdrop {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      `
          : null}
    }

    ${({ hasManyOptions }) =>
      hasManyOptions
        ? `
      &:last-child {
        margin-left: 1px;
        color: #919eab;
        min-width: 161px;
        border-left: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

        option {
          color: black;
        }

        .Polaris-Select__Backdrop {
          left: -1px;
          border-left: 0;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    `
        : null}
  }

  #${selectId} {
    cursor: pointer;

    &:focus ~ .Polaris-Select__Backdrop {
      left: 0;
    }
  }
`;

export const getSearchOptions = async (value: string): Promise<CombinedSearchOptions[]> => {
  const optionsSettled = await Promise.allSettled([getIdOptions(value), getEmailOptions(value)]);

  const options = optionsSettled.flatMap(optionResult => {
    if (optionResult.status === "fulfilled") {
      return optionResult.value;
    }
    return [];
  });

  return options;
};

export const TableSearch = ({
  loadData,
  tableProps,
  selectOptions,
  setPageNumber,
  setFilter,
}: TableSearchProps) => {
  const client = useApolloClient();
  const dispatch = useSmartrrVendorDispatch();

  const filterField = tableProps.filter.hasOwnProperty("shopifyId") ? "shopifyId" : "emailOrName";

  const [isLoading, setLoading] = useState<boolean>(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState<CombinedSearchOptions[]>([]);

  const [debouncedUpdate] = useFilterUpdate(value => dispatch(loadData(value)), setPageNumber);
  const [searchText, setSearchText] = useState<string>(getStringFromFilter(tableProps.filter, filterField));

  const selectedValues = useMemo(() => getArrayFromFilter(tableProps.filter, filterField), [tableProps.filter]);
  const hasManyOptions = useMemo(() => selectOptions.length > 1, [selectOptions]);

  const textProps = {
    placeholder: "Search",
    value: searchText,
  };

  const omitFilterField = (filterField: string, filter: SmartrrFilterType) => {
    if (filterField === "emailOrName") {
      return { ...omit(filter, "shopifyId"), [filterField]: undefined };
    }

    if (filterField === "shopifyId") {
      return { ...omit(filter, "emailOrName"), [filterField]: undefined };
    }

    return { ...tableProps.filter, [filterField]: undefined };
  };

  const onSearchTypeChange = (newValue: "emailOrName" | "shopifyId") => {
    setFilter({ ...omitFilterField(newValue, tableProps.filter), [newValue]: undefined });
    setSearchText("");
    fetchOptionsAndSetState(newValue);
  };

  const fetchOptionsAndSetState = useCallback(
    debounce(async (value?: string) => {
      const options = await getSearchOptions(value ?? "");

      setAutocompleteOptions(options);
    }, 250),
    [client]
  );

  const onSearchValueChange = async (value: string) => {
    setLoading(true);
    setSearchText(value);

    const val = Array.isArray(value) ? value[0] : value;

    if (value) {
      await fetchOptionsAndSetState(val);
    } else {
      setFilter({
        ...omitFilterField(filterField, tableProps.filter),
        [filterField]: value,
      });
    }

    setLoading(false);
  };

  const onSearchValueSelect = (value: string[]) => {
    setSearchText(value.join(";"));
    const selectedOption = autocompleteOptions.find(v => value.includes(v.value));
    if (selectedOption) {
      setFilter({
        ...omitFilterField("shopifyId", tableProps.filter),
        ...omitFilterField("emailOrName", tableProps.filter),
        [selectedOption.type]: selectedOption.value,
      });
    } else {
      setFilter({
        ...omitFilterField(filterField, tableProps.filter),
        [filterField]: value,
      });
    }
  };

  useEffect(() => {
    debouncedUpdate(
      tableProps.pageNumber,
      tableProps.pageSize,
      tableProps.orderByField,
      tableProps.orderByValue,
      false,
      tableProps.filter
    );
  }, [tableProps.pageNumber, tableProps.pageSize, tableProps.orderByField, tableProps.orderByValue]);

  //if filter has changed set triggeredBySearch === true, which sets page number to 0
  //its only needed for filter to correctly display pages
  //in case page size has been decreased since the last request
  useEffect(() => {
    debouncedUpdate(
      tableProps.pageNumber,
      tableProps.pageSize,
      tableProps.orderByField,
      tableProps.orderByValue,
      true,
      tableProps.filter
    );
  }, [tableProps.filter]);

  useEffect(() => {
    fetchOptionsAndSetState(filterField);
  }, []);

  return (
    <StyledWrapper hasManyOptions={hasManyOptions}>
      <AutoCompleteWithTextField
        options={autocompleteOptions}
        textFieldProps={{
          ...textProps,
          value: Array.isArray(textProps.value) ? textProps.value.join(";") : textProps.value,
        }}
        selected={selectedValues}
        onChange={onSearchValueChange}
        onSelect={onSearchValueSelect}
        loading={!!searchText && isLoading}
        emptyState={
          !!searchText && !isLoading && !autocompleteOptions.length ? (
            <Text variant="bodyMd" as="span" alignment="center">
              {filterField === "shopifyId" ? "No subscriptions found" : "No data found"}
            </Text>
          ) : undefined
        }
      />
      {!!hasManyOptions && (
        <Select
          id={selectId}
          label=""
          options={selectOptions}
          value={filterField}
          onChange={onSearchTypeChange}
          labelHidden
        />
      )}
    </StyledWrapper>
  );
};
