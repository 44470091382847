import { captureException } from "@smartrr/shared/utils/captureException";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { capitalize } from "lodash";

import { getCustomers } from "@vendor-app/app/_state/actionCreators/customers";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { MAX_SEARCH_OPTIONS } from "../constants";

export interface CombinedSearchOptions {
  label: string;
  value: string;
  type: "emailOrName" | "shopifyId";
}

export const getEmailOptions = async (email?: string): Promise<CombinedSearchOptions[]> => {
  try {
    const resp = await getCustomers({
      queryParams: {
        pageNumber: 0,
        pageSize: MAX_SEARCH_OPTIONS,
        filterLike: {
          emailOrName: email ?? "",
        },
      },
    });

    if (resp.type === "success") {
      return resp.body.data.reduce((acc: CombinedSearchOptions[], customer) => {
        if (customer.email) {
          acc.push({
            label: `${capitalize(customer?.firstName)} ${
              customer?.lastName ? capitalize(customer.lastName) : customer.email
            }`,
            value: customer.email,
            type: "emailOrName",
          });
        }
        return acc;
      }, []);
    }
    captureException("Can't fetch customers data", resp.message);
    return [];
  } catch (error) {
    captureException("Can't fetch customers data", error);
    return [];
  }
};

export const getIdOptions = async (id?: string): Promise<CombinedSearchOptions[]> => {
  try {
    const resp = await typedFrontendVendorApi.getReq("/purchase-state/shopify-ids", {
      query: {
        shopifyId: id,
        limit: MAX_SEARCH_OPTIONS,
      },
    });

    if (resp.type === "success") {
      return resp.body.map(
        ({ shopifyId }: { shopifyId: string }): CombinedSearchOptions => ({
          label: String(shopifyGidToNumber(shopifyId)),
          value: String(shopifyGidToNumber(shopifyId)),
          type: "shopifyId",
        })
      );
    }
    captureException("Can't fetch shopify ids", resp.message);
    return [];
  } catch (error) {
    captureException("Can't fetch shopify ids", error);
    return [];
  }
};
